import Chart from './Chart'
import Highcharts from 'highcharts'
import loadMap from 'highcharts/modules/map'
import globalMap from '@highcharts/map-collection/custom/world.geo.json'

loadMap(Highcharts)

class MapChart extends Chart {
  constructor (ref, series, options) {
    super(ref, series)

    this.setOptions(this.mapChartOptions(options))

    this.init()
  }

  init () {
    this.instance = Highcharts.Map(
      this.ref,
      this.mergeOptions(this.defaultOptions(), this.options)
    )
  }

  mapChartOptions ({ title }) {
    return {
      chart: {
        map: globalMap
      },
      title: {
        text: title,
        align: 'left',
        style: {
          fontWeight: 'bold'
        }
      },
      mapNavigation: {
        enabled: true,
        buttons: {
          zoomIn: { y: 30 },
          zoomOut: { y: 60 }
        },
        enableMouseWheelZoom: false
      },
      legend: {
        layout: 'vertical',
        borderWidth: 0,
        floating: false,
        align: 'left'
      },
      colorAxis: {
        min: 1,
        type: 'logarithmic',
        minColor: '#cfe0e7', // HF blueish color
        maxColor: '#000000' // HF Primary Red
      }
    }
  }
}

export default MapChart
